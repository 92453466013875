"use client";

export const ThreadHighlight: React.FC<{
  threadRef: React.RefObject<HTMLDivElement | null>;
}> = ({ threadRef }) => {
  if (!threadRef.current) return null;
  const target = threadRef.current;

  return (
    <>
      <div
        className="absolute left-0 top-0 rounded-r-lg bg-slate-800/60"
        style={{
          width: target.clientWidth - 8,
          height: target.clientHeight,
          transform: `translateY(${target.offsetTop}px)`,
          willChange: "transform, height",
        }}
      />
      <div
        className="absolute left-0 top-0 transition-[transform,height]"
        style={{
          width: target.clientWidth - 8,
          height: target.clientHeight,
          transform: `translateY(${target.offsetTop}px)`,
          willChange: "transform, height",
        }}
      >
        <div className="h-full w-[1px] bg-blue-300" />
      </div>
    </>
  );
};
